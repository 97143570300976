#MAP_VIEW_CONTAINER {
	display: inline-block;
	position: relative;
	height: 100%;
	width: 100%;
	left: 0;
	right: 0;

	--page-width: 11in;
	--page-height: 8.5in;
	--page-margin: 7mm;

	--map-print-width: calc(var(--page-width) - 2 * var(--page-margin));
	/* - 1px to avoid extra blank page */
	--map-print-height: calc(var(--page-height) - 2 * var(--page-margin) - 1px);

	page-break-after: avoid;
	overflow: hidden;
}

.Map {
	display: inline-block;
	position: relative;
	height: 100%;
	min-height: -moz-available; /* WebKit-based browsers will ignore this. */
	min-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
	min-height: stretch;
	height: stretch;
	width: 100%;
	font-size: 14px;
	--map2-print-height: calc(var(--map-print-height) - 50px);

	.Map__container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.Map__overlay {
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		overflow: hidden;

		> * {
			pointer-events: all;
		}

		.MapControl-left,
		.MapControl-right,
		.MapControl-top-left,
		.MapControl-top-right,
		.MapControl-bottom-left,
		.MapControl-bottom-right {
			position: absolute;
			padding: 8px;
			pointer-events: none;

			> * {
				pointer-events: all;
			}

			> * + * {
				margin-top: 8px;
			}
		}

		.MapControl-left {
			left: 0;
			top: 0;
			height: 100%;
		}

		.MapControl-right {
			right: 0;
			top: 0;
			height: 100%;
		}

		.MapControl-top-left {
			top: 0;
			left: 0;
		}

		.MapControl-top-right {
			top: 0;
			right: 0;
			text-align: right;
		}

		.MapControl-bottom-left {
			bottom: 0;
			left: 0;
		}
	}
}

@page {
	size: landscape;
	margin: 7mm;
}

@media print {
	.Map {
		width: var(--map-print-width);
		height: var(--map2-print-height);
	}
}

/**
 * A4 landscape
 */
@media print and (min-width: 210mm) and (max-width: 297mm) {
	#MAP_VIEW_CONTAINER {
		--page-width: 210mm;
		--page-height: 297mm;

		--map-print-width: calc(var(--page-width) - 2 * var(--page-margin));
		/* - 1px to avoid extra blank page */
		--map-print-height: calc(var(--page-height) - 2 * var(--page-margin) - 1px);

		width: var(--map-print-width);
		height: var(--map-print-height);
	}
}

/* mapbox control using 1px as the css minifier is striping of units for 0px and that breaks css-max function */
.mapboxgl-control-container {
	position: absolute;
	top: 0;
	left: 0;
	left: max(calc(50vw - 277px), 1px);
	right: 0;
	right: max(calc(50vw - 277px), 1px);
	bottom: 120px;
	z-index: 0;
	pointer-events: none;
}

@media screen and (min-width: 741px) {
	.mapboxgl-control-container {
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}

	.left-sidebar-open .mapboxgl-control-container {
		left: max(calc(50vw - 277px - 120px), 1px);
		right: max(calc(50vw - 277px - 120px), 1px);
		bottom: 120px;
		z-index: 0;
	}
}

@media screen and (min-width: 981px) {
	.left-sidebar-open .mapboxgl-control-container,
	.right-sidebar-open .mapboxgl-control-container {
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}

	.left-sidebar-open.right-sidebar-open .mapboxgl-control-container {
		left: max(calc(50vw - 277px - 240px), 1px);
		right: max(calc(50vw - 277px - 240px), 1px);
		bottom: 120px;
		z-index: 0;
	}
}

@media screen and (min-width: 1251px) {
	.left-sidebar-open.right-sidebar-open .mapboxgl-control-container {
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}
}

.mapboxgl-ctrl {
	margin: 0 7px !important;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl,
.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
	margin-top: 4px !important;
}
.mapboxgl-ctrl-top-left .mapboxgl-ctrl + .mapboxgl-ctrl,
.mapboxgl-ctrl-top-right .mapboxgl-ctrl + .mapboxgl-ctrl {
	margin-top: 4px !important;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl,
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
	margin-bottom: 4px !important;
}
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl + .mapboxgl-ctrl,
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl + .mapboxgl-ctrl {
	margin-bottom: 4px !important;
}

.mapboxgl-ctrl-logo {
	margin: 0 !important;
	height: 21px !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group,
.mapboxgl-ctrl.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl.mapboxgl-ctrl-scale {
	background: var(--background) !important;
	color: var(--color) !important;
}

.mapboxgl-ctrl-attrib-inner a {
	color: var(--link-color) !important;
}

.mapboxgl-ctrl-attrib-inner a:hover {
	color: var(--link-color-hover) !important;
}

.mapboxgl-ctrl .mapboxgl-ctrl-icon {
	border-bottom-color: var(--divider) !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-scale {
	background: var(--background-a) !important;
	outline: 2px solid var(--background-a) !important;
	border-color: var(--color) !important;
}

/* mapbox popup */
.mapboxgl-popup-content {
	background: var(--background2) !important;
	color: var(--color) !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
	border-bottom-color: var(--background2) !important;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
	border-top-color: var(--background2) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
	border-right-color: var(--background2) !important;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
	border-left-color: var(--background2) !important;
}