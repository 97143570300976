.TranslationTextField {
  --padding: 21px;
  --right-margin: 21px;
  --left-margin: 5px;
}

.TranslationTextField__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TranslationTextField__content {
  padding-left: var(--padding);
  padding-right: var(--padding);
  margin-left: var(--left-margin);
  margin-right: var(--right-margin);
  border: 3px solid hsla(134, 43%, 50%, 0.3);
  border-top: none;
  border-bottom: none;
}

.ClassInputList .TranslationTextField__content {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}

.ClassInputList .TranslationTextField__title h3 {
  font-size: 0.84em;
}

.ClassInputList .TranslationTextField__content > div:first-child {
  margin-top: -3em;
}
