.ReportProductPage {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  --block-height: 36px;
}

.ReportProductPage__block {
  width: 100%;
  background: var(--light-grey);
  height: var(--block-height);
  flex: 0 0 var(--block-height);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}

.ReportProductPage__block * {
  color: black !important;
}

.ReportProductPage__container {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}

.hasParcel .ReportProductPage__container {
  height: calc(100% - var(--block-height));
}

.ReportProductPage__Map {
  width: 100%;
  position: relative;
}

.ReportProductPage__Map img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ReportProductPage__Information {
  height: 100%;
  padding: 0 0.4em;
  flex: 0 0 200px;
  display: grid;
  grid-gap: 1.6em;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 3fr 40px;
  padding: calc(var(--spacing) * 2);
}

.hasParcel .ReportProductPage__Information {
  grid-gap: 0.2em;
}

.ReportProductPage .ReportLegend {
  justify-self: center;
}

.ReportProductPage .ReportLogo {
  align-self: end;
  justify-self: center;
}

.ReportProductPage hr {
  width: 6em;
  height: 1px;
  background: black;
  outline: none;
  border: none;
  margin: 0;
}

.ReportProductPage__LegendSection {
  align-self: center;
}

.ReportProductPage__LegendSection hr {
  margin: 0.4em 0;
}

#ReportHome .LegendTitle {
  font-size: 0.86em;
}
