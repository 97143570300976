.account-content {
  align-items: center;
  padding: 16px;
}
.account-content > .Paper {
  width: min(80ch, 100vw);
}

.account-nav {
  background-color: var(--background-a);
}
.account-nav > a {
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  color: hsl(0, 0%, 75%);
  border-left: 2px solid transparent;
}

.account-nav > a:active,
.account-nav > a.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 2px solid hsl(0, 0%, 100%);
  color: hsl(0, 0%, 100%);
}

.account-nav > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 2px solid hsl(0, 0%, 75%);
  color: hsl(0, 0%, 75%);
}
