.attempt-logs {
  box-sizing: border-box;
  min-height: 19px;
  overflow-x: auto;
  width: 100%;
  font-size: 12pt;
  background-color: rgb(38, 38, 38);
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(127, 127, 127);
  padding: 12px;
}

.attempt-logs * {
  color: rgb(250, 250, 250);
  font-family: Monospace !important;
}

.attempt-logs.wrap {
  width: 100%;
  overflow-x: hidden;
}

.attempt-logs.wrap p {
  width: 100%;
  overflow-x: hidden;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}
