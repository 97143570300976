#ReportHome .ReportLegend {
  --item-height: 15px;
  align-self: start;
  justify-self: left;
}

#ReportHome .ReportLegend .item {
  padding: 0.06em;
  align-items: center;
}

#ReportHome .ReportLegend .itemLabel {
  font-size: 0.8em;
  color: var(--report-text);
}

#ReportHome .ReportLegend .itemColor {
  height: var(--item-height);
  width: var(--item-height);
  padding: 0;
  border-radius: 50%;
  border: 1px solid #888;
}

#ReportHome .ReportLegend .data_Legend.vertical {
  flex-direction: column-reverse;
}
