.gps-toggle {
  flex-direction: column;
}

.offset-values__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.offset-values__title[class*='MuiTypography-'] {
  margin-right: 8px;
}

.offset-values__btn--reset[class*='MuiButton-'] {
  min-width: unset;
}

.offset-values__value {
  text-align: center;
  background-color: #cccccc;
  color: #808080;
  width: 40px;
  margin-right: 8px;
}

.offset-values__value--title[class*='MuiTypography-'] {
  margin-right: 2px;
}
