.TableRoot {
	font-size: 14px;
}

.Table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
}

.TableHeader,
.TableData {
	text-align: left;
	text-align: start;
	padding-left: 10px;
}

.TableRowActions {
	text-align: right;
	white-space: nowrap;
	padding-right: 10px;
}

.TableHeader {
	background: var(--background);
	position: sticky;
	top: 0;
	z-index: 1;
	white-space: nowrap;
}

.TableRow.selected {
	background: var(--background2);
}

.TableRow.clickable {
	cursor: pointer;
}

.TableHeader.orderable {
	cursor: pointer;
}

.TableTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.TableTitle .titleText {
	padding-left: 16px;
}

.TableActions {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}

.Table .DetailsRow {
	margin-left: 16px;
	margin-right: 8px;
	padding: 8px 8px 8px 16px;
	border-left: 2px solid var(--divider);
}

.TableRow {
	height: 60px;
}

.TableRow:nth-of-type(odd) {
    background: var(--background2);
}

td.right-align {
	text-align: right;
}

td.center-align {
	text-align: center;
}

td.left-align {
	text-align: left;
}
