:root {
  --cancelled-background: #f5bc42;
  --cancelled-background-hover: #f5b325;
  --cancelled-stroke: #f5b325;

  --error-background: #fd6f48;
  --error-background-hover: rgb(151, 66, 43);
  --error-stroke: rgb(177, 77, 50);

  --timeout-background: #bdbdbd;
  --timeout-background-hover: rgb(113, 113, 113);
  --timeout-stroke: #757575;

  --running-pulse-background-start: #068bf8;
  --running-pulse-background-end: #42a5f5;

  --running-pulse-text-start: rgb(216, 216, 216);
  --running-pulse-text-end: #fff;

  --selected-background: rgb(51, 127, 69);

  --node-background: #4ab663;
  --node-hover-background: rgb(44, 109, 59);
  --node-stroke: rgb(51, 127, 69);
}
.light {
  --edge-color: rgba(0, 0, 0, 0.38);
  --graph-background: #fafafa;
  --graph-border: rgba(0, 0, 0, 0.12);

  --pending-background: #fff;
  --pending-stroke: #4ab663;

  --running-stroke: #000;

  --running-hover-stroke: rgba(0, 0, 0, 0.54);

  --text-color: #323232;
  --selected-stroke: #323232;

  --icon-hover: rgb(42, 42, 42);
}

.dark {
  --edge-color: rgba(255, 255, 255, 0.5);
  --graph-background: #303030;
  --graph-border: rgba(255, 255, 255, 0.12);

  --pending-background: #424242;
  --pending-stroke: #4ab663;

  --running-stroke: #fff;

  --running-hover-stroke: rgba(255, 255, 255, 0.7);

  --text-color: #fff;
  --selected-stroke: #fff;

  --icon-hover: rgb(216, 216, 216);
}

@keyframes pulse {
  0% {
    fill: var(--running-pulse-background-start);
  }
  100% {
    fill: var(--running-pulse-background-end);
  }
}
@keyframes pulsetext {
  0% {
    fill: var(--running-pulse-text-start);
  }
  100% {
    fill: var(--running-pulse-text-end);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate(0px, 6px);
  }
  100% {
    transform: rotate(360deg) translate(0px, 6px);
  }
}

.pipeline-graph {
  width: 100%;
  height: 100%;
  border: 2px solid var(--graph-border);
  overflow: scroll;
  background-color: var(--graph-background);
}
.pipeline-graph svg {
  padding: 12px;
}
.pipeline-graph svg .graph > polygon {
  fill: var(--graph-background);
}
.pipeline-graph svg .graph .node {
  cursor: pointer;
}
.pipeline-graph svg .graph text {
  fill: var(--text-color);
  font-size: 12px;
}
.pipeline-graph svg .graph .edge > * {
  stroke: var(--edge-color);
}
.pipeline-graph svg .graph .edge > polygon {
  fill: var(--edge-color);
}
.pipeline-graph svg .graph .node.running:hover a > path,
.pipeline-graph svg .graph .node.running:hover a > polygon {
  stroke: var(--running-hover-stroke);
}

.pipeline-graph svg .graph .node.running a > path,
.pipeline-graph svg .graph .node.running a > polygon {
  animation: pulse 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  animation-direction: alternate;
  stroke: var(--running-stroke);
}
.pipeline-graph svg .graph .node.running a > text {
  animation: pulsetext 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  animation-direction: alternate;
}
.pipeline-graph svg .graph .node.running a > text:nth-child(3) {
  animation: rotate 1s linear infinite;
  transform-box: fill-box;
  transform-origin: bottom;
}
.pipeline-graph svg .graph .node.timeout:hover a > path,
.pipeline-graph svg .graph .node.timeout:hover a > polygon {
  fill: var(--timeout-background-hover);
}
.pipeline-graph svg .graph .node.timeout a > path,
.pipeline-graph svg .graph .node.timeout a > polygon {
  fill: var(--timeout-background);
  stroke: var(--timeout-stroke);
}
.pipeline-graph svg .graph .node.timeout a > text:nth-child(3) {
  cursor: pointer;
  transform: translate(4px, 6px);
}
.pipeline-graph svg .graph .node.cancelled:hover a > path,
.pipeline-graph svg .graph .node.cancelled:hover a > polygon {
  fill: var(--cancelled-background-hover);
}
.pipeline-graph svg .graph .node.cancelled a > path,
.pipeline-graph svg .graph .node.cancelled a > polygon {
  fill: var(--cancelled-background);
  stroke: var(--cancelled-stroke);
}
.pipeline-graph svg .graph .node.cancelled a > text:nth-child(3) {
  cursor: pointer;
}
.pipeline-graph svg .graph .node.error:hover a > path,
.pipeline-graph svg .graph .node.error:hover a > polygon {
  fill: var(--error-background-hover);
}
.pipeline-graph svg .graph .node.error a > path,
.pipeline-graph svg .graph .node.error a > polygon {
  fill: var(--error-background);
  stroke: var(--error-stroke);
}
.pipeline-graph svg .graph .node.error a > text:nth-child(3) {
  cursor: pointer;
  transform: translate(-6px, 6px);
}
.pipeline-graph svg .graph .node.pending a > path,
.pipeline-graph svg .graph .node.pending a > polygon {
  fill: var(--pending-background);
  stroke: var(--pending-stroke);
}
.pipeline-graph svg .graph .node.selected a > path,
.pipeline-graph svg .graph .node.selected a > polygon {
  fill: var(--selected-background) !important;
  stroke: var(--selected-stroke) !important;
}
.pipeline-graph svg .graph .node:hover a > path,
.pipeline-graph svg .graph .node:hover a > polygon {
  fill: var(--node-hover-background);
}
.pipeline-graph svg .graph .node a > path,
.pipeline-graph svg .graph .node a > polygon {
  fill: var(--node-background);
  cursor: pointer;
  stroke: var(--node-stroke);
  transition: all 0.25s;
}
.pipeline-graph svg .graph .node a > text {
  fill: var(--text-color);
  stroke: transparent;
  z-index: 1000;
}
.pipeline-graph svg .graph .node a > text:nth-child(3) {
  cursor: pointer;
  transform: translate(0px, 6px);
  font-family: Material Icons;
}
.pipeline-graph svg .graph .node a > text:hover:nth-child(3) {
  fill: var(--icon-hover);
}
