.ShallowTable__header {
	background-color: var(--background);
}

.ShallowTable__row {
	&:nth-of-type(odd) {
		background-color: var(--background2);
	}
	td:nth-of-type(odd) {
		font-weight: bold;
	}
}
