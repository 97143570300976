.adjust_position_dialog {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 8px;
  z-index: 2;
  text-align: center;
}

.adjust_position_dialog .submit_offset_btn {
  color: hsl(134, 43%, 50%);
}

.adjust_position_dialog h3 {
  margin-bottom: 8px;
}

.adjust_position_dialog .actions {
  display: flex;
  margin-top: 8px;
  justify-content: space-around;
}

/*accoring to MUI grid*/
/*xs, extra-small: 0px or larger*/
/*sm, small: 600px or larger*/
/*md, medium: 960px or larger*/
/*lg, large: 1280px or larger*/
/*xl, extra-large: 1920px or larger*/

@media (min-width: 600px) {
  .adjust_position_dialog {
    bottom: 34px;
    width: fit-content;
  }
}
