#ReportCover {
  height: calc(100% - 1em);
  position: relative;
}

#ReportCover .ReportCover__headline {
  font-size: 1.6em;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

#ReportCover .ReportCover__subheader {
  font-size: 1.8em;
  margin-top: 0.6em;
  letter-spacing: 2.8px;
  font-weight: 300;
}

#ReportCover .ReportCover__subheader:after {
  content: '';
  position: relative;
  display: block;
  width: 100px;
  height: 0.3em;
  margin-top: 0.8em;
  margin-bottom: 1.6em;
  background: var(--green);
}

#ReportCover .ReportCover__prepared p {
  color: var(--grey);
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 1em;
}
