.speed_tracker {
  padding: 8px;
  background-color: #424242d9;
  width: 300px;
  max-width: 100%;
}

.speed_tracker_top {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
}

.speed_tracker_top .item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.speed_tracker_top .item button {
  /*padding: 0 !important;*/
  min-width: unset !important;
}

.speed_tracker_top .item.center {
  text-align: center;
}

.speed_tracker_top .item button.btn--close {
  padding: 0;
}

.speed_tracker_top .prescribed-speed {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.speed_tracker_top .prescribed-speed h1,
.speed_tracker_top .prescribed-speed h3,
.speed_tracker_top .prescribed-speed h6 {
  font-weight: 600;
}

.speed_tracker_top .prescribed-speed h1 {
  margin-bottom: 2px;
}

.speed_tracker_bottom {
  padding: 8px 8px 0 8px;
  display: flex;
  flex-direction: row;
}

.speed_tracker_bottom .item {
  padding: 0;
}

.speed_tracker_bottom .item:first-of-type {
  border-right: 1px solid #ccc;
  flex-basis: 40%;
}

.speed_tracker_bottom .item:last-of-type {
  flex-basis: 60%;
  padding-top: 0;
}

.speed_tracker_bottom .item:last-of-type .line {
  justify-content: flex-end;
  align-items: center;
}

.speed_tracker_bottom .item:last-of-type .line .label {
  margin-right: 8px;
}

.speed_tracker_bottom .item:last-of-type .line:first-of-type {
  margin-bottom: 0px;
}

.speed_tracker_bottom .line {
  display: flex;
  flex-direction: row;
}

.animated-triangle.down {
  transform: rotate(180deg);
}
