.ClassInputList {
  --right-padding: 0;
  --left-padding: 21px;
  --right-margin: 21px;
  --left-margin: 5px;
}

.ClassInputList__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ClassInputList__value_section {
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: 2em;
  margin-top: -1em;
}

.ClassInputList__value_section > div:first-child {
  margin-top: 1em;
}

.ClassInputList__content {
  padding-left: var(--left-padding);
  padding-right: var(--right-padding);
  margin-left: var(--left-margin);
  margin-right: var(--right-margin);
  border: 3px solid hsla(134, 43%, 50%, 0.3);
  border-top: none;
  border-bottom: none;
  display: grid;
  grid-gap: 2em;
}
