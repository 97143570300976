#ReportHome {
  --page-padding: var(--spacing);
  --page-height: 8.5in;
  --page-width: 11in;
}

#ReportHome .ReportHeader {
  width: 100%;
  height: 64px;
  margin-bottom: calc(var(--page-padding) * 2);
  background: url('./assets/Reports_header_image.png');
  background-size: 100% 100%;
}

#ReportHome .ReportSection {
  font-size: 18px;
  margin: 0 auto;
  margin-top: 30px;
  height: var(--page-height);
  width: var(--page-width);
  background: white;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

#ReportHome .ReportSection a.scroll-link {
  position: absolute;
  top: calc(-1 * var(--appbar-height));
}

#ReportHome .ReportSection__fluid {
  min-height: var(--page-height);
  padding-bottom: 50px;
}

#ReportHome .ReportSection__withLogo {
  position: relative;
}

#ReportHome .ReportSection__withLogo:before {
  background: url('./assets/Reports_logo.png');
  background-position: bottom right;
  background-size: auto 30px;
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  margin: -2em;
}

#ReportHome .ReportSection:first-of-type {
  margin-top: var(--appbar-height);
}

#ReportHome .ReportSection:last-child {
  margin-bottom: 30px;
}

/* reports should not be dark mode */
#ReportHome p {
  color: #222;
}

#ReportHome .report_container {
  position: relative;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}

#ReportHome .ReportHeader {
  top: 1em;
  position: relative;
}

@media (min-width: 44em) {
  #ReportHome {
    --page-padding: calc(var(--spacing) * 5);
    width: 99%;
  }
}

@page {
  size: landscape;
  margin: 7mm;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }

  #ReportAppBar {
    display: none;
  }

  #ReportHome {
    --print-margin: 7mm;
  }

  #ReportHome .ReportSection {
    margin: 0 !important;
    border-radius: 0;
    page-break-after: always;
    page-break-before: always;
    min-height: calc(var(--page-height) - var(--print-margin) * 2.1);
    height: calc(var(--page-height) - var(--print-margin) * 2.1);
    width: calc(var(--page-width) - var(--print-margin) * 2.1);
    overflow: hidden;
  }

  #ReportHome .ReportSection a.scroll-link {
    top: 0;
  }
}

/**
 * A4 landscape (hacks)
 * Somehow I can't put four media queries in to match the exact dimensions
 * Can't use (width: ??mm)
 * Can't specify both width and height
 * Can't use min/max to get exact width/height
 * No idea why min and max of the width/height works.
 */
@media print and (min-width: 210mm) and (max-width: 297mm) {
  #ReportHome {
    --page-height: 210mm;
    --page-width: 297mm;
  }
}

/* ReportAppBar */

.Report__PrintWrapper {
  position: relative;
}

.Report__PrintWrapper .progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: var(--blue) !important;
}

.Report__loadingProgress {
  margin-left: 10px;
  z-index: 10;
  font-style: italic;
}
