.notification__container {
  display: flex;
  align-items:center;
  padding: 16px 0;
  cursor: pointer;
}

.notification__container > div > h4 {
  margin: 0;
}

.notification__container > div > div {
  opacity: 0.7;
}