.PackageSelection__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PackageSelection__sticky {
  position: sticky;
  background: var(--background-a);
  backdrop-filter: blur(5px);

  border-bottom: 1px solid var(--divider);

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.PackageSelection__nest {
  margin-left: 16px;
  padding: 8px 8px 8px 16px;
  border-left: 1px solid var(--divider);
}

.PackageSelection__secondary {
  background: var(--background-light);
}
