.sample-plan-details {
  background-color: var(--background2);
}

.sample-plan-detail-section {
    position: absolute;
    top: 0;
    left: 0;
}


.sample-plan-detail-side-panel {
  position: absolute;
  height: 100%;
  width: 375px;
  background-color: var(--background2);
  overflow: visible;
  transition: width 350ms ease-in-out;
}

.sample-plan-detail-side-panel.closed {
  width: 30px;
}

.sample-plan-detail-info-panel {
  left: 0;
  width: 375px;
  background-color: var(--background2);
  z-index: 11;
  padding: 16px;
}

.sample-plan-detail-map-panel-table.collapse {
  display: none;
}

.sample-plan-percentage-bar-container {
  width: 284px;
  padding-right: 16px;
}

.sample-plan-table-row {
  height: 60px;
}

.sample-plan-table-row-cell {
  border-bottom: 0px;
}

.sample-plan-table-row:nth-of-type(odd) {
  background: var(--background2);
}

.sample-plan-location-icon {
  height: 19px;
  -webkit-transition: color 200ms linear;
  -moz-transition: color 200ms linear;
  -o-transition: color 200ms linear;
  transition: color 200ms linear;
}

.sample-plan-block-location-icon.selected {
  color: #4ab663;
}

.sample-plan-block-open-button {
  width: 20px;
  height: 20px;
  -webkit-transition: color 200ms linear;
  -moz-transition: color 200ms linear;
  -o-transition: color 200ms linear;
  transition: color 200ms linear;
}

.sample-plan-block-open-button.selected {
  background-color: #4ab663;
}

.sample-plan-block-header-cell {
  border-bottom: 0px;
}

.sample-plan-block-body-cell {
  border-bottom: 0px;
  height: 40px;
  padding: 0px 16px;
}

.sample-plan-block-row {
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.sample-plan-block-row:hover {
  background-color: #4ab6630e;
}

.sample-plan-block-row.selected {
  background-color: #4ab66345;
}

.sample-plan-detail-side-panel .collapse-arrow {
  background-color: var(--green);
  position: absolute;
  right: -15px;
  top: 19px;
  z-index: 100;
  transition: right 300ms ease-in-out, transform 500ms ease-in-out;
}

.sample-plan-detail-side-panel .collapse-arrow.closed {
  transform: rotate(0.5turn);
}

.sample-plan-details .mapboxgl-ctrl-bottom-left {
  margin-left: 30px;
}

.sample-plan-details.side-panel-open .mapboxgl-ctrl-bottom-left {
  margin-left: 375px;
}

.sample-plan-details .MapControl-top-left {
  margin-left: 30px;
}

.sample-plan-details.side-panel-open .MapControl-top-left {
  margin-left: 375px;
}
