.react-draggable[aria-labelledby="draggable-dialog-title"] {
  top: unset !important;
  bottom: 120px;
  left: 50% !important;
  margin-left: -269px;
  max-width: 700px;
  min-width: 450px;
  z-index: 10;
  opacity: 0.9;

  @media (max-width: 700px) {
    margin-left: calc(-100% / 2);
  }
}
