.MapDataContainer {
  margin: 1rem auto;
  width: 98%;
  max-width: 1000px;
}

.MapDataContainerSmall {
  margin: 1rem auto;
  width: 90%;
}

#MapDataPage {
  padding-bottom: 50vh;
}

#MapDataPage .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#MapDataPage .actions > * {
  margin-right: 1em;
}

#MapDataPage .actions > *:last-child {
  margin-right: 0;
}

#MapDataPage hr {
  margin: 2rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  justify-content: stretch;
  align-items: center;
  height: 100%;
}

.wrapper .Paper {
  width: 75%;
}

@media (max-width: 1279.95px) {
  .wrapper .Paper {
    width: 85%;
  }
}

@media (max-width: 959.95px) {
  .wrapper {
    align-items: flex-start;
  }
  .wrapper .Paper {
    width: max-content;
    margin-right: 12px;
  }
}

@media (max-width: 599.95px) {
  .wrapper {
    align-items: flex-start;
  }
  .wrapper .Paper {
    margin-right: 12px;
  }
}
