.miller-columns .miller-column {
	flex: 1;
	width: 350px;
	position: relative;
}

.miller-columns .miller-column .miller-column-list {
	max-height: 500px;
	width: 100%;
	overflow-y: auto;
}

.miller-columns .miller-column .miller-column-list .selected-item {
	background-color: var(--background-selected);
}

.miller-columns .miller-column-divider {
	width: 1px;
	margin-left: var(--spacing);
	margin-right: var(--spacing);
	background-color: var(--divider);
}

.order-details-column {
	max-height: 500px;
	overflow-y: auto;
	margin-bottom: calc(var(--spacing) * 2);
	height: 100%;
}

.order-details-column > * {
	margin-top: var(--spacing);
	margin-bottom: var(--spacing);
}
