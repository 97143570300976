.StatsTableItem {
  text-align: start;
  margin: 0px 20px;
}

.StatsInnerGrid {
  display: flex;
  align-items: center;
  height: 20px;
}

.StatLabel {
  user-select: none;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.TrendItem {
  margin: 0px 10px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: fadeIn 150ms ease-in forwards;
}

.TrendLabel {
  font-size: 0.7em;
  margin: 0px 5px;
  user-select: none;
}
