.tag {
	padding-left: 12px !important;
	padding-right: 12px !important;
	margin: 5px;
	max-width: 350px;
	overflow: hidden;
	span {
		max-width: 350px;
		overflow: hidden;
		padding: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
