html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  --sidebar-width: min(66vw, 300px);
}

.ui-app,
.ui-app-row,
.ui-sidebar {
  height: 100%;
}

.ui-app,
.ui-app-row {
  overflow: hidden;
}

.ui-app,
.ui-app-row,
.ui-nav,
.ui-sidebar,
.ui-content,
.ui-content-row {
  display: flex;
  position: relative;
}

.ui-app,
.ui-sidebar,
.ui-content {
  flex-direction: column;
}

.ui-nav {
  width: auto;
}

.ui-nav,
.ui-app-row,
.ui-content-row {
  flex-direction: row;
}

.ui-sidebar,
.ui-content,
.ui-content-column {
  overflow: auto;
}

.ui-content,
.ui-content-column {
  flex: 1;
}

svg.ui-font-icon {
  height: 1em;
  width: 1em;
}

.main-nav {
  min-height: 48px;
  background: var(--green);
  color: var(--color);
  align-items: center;
}
.main-nav * {
  color: var(--color);
}
.main-nav-title-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 16px;

  .nav-children-container {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }
}
* + .main-nav-title-container {
  padding-left: 0;
}
.center-nav-title .main-nav-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.main-nav-tools {
  padding: 0 8px;
}
.main-nav-subscription {
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.print-title {
  text-align: center;
  height: 48px;
  background: var(--green);
}

.beta-banner {
  justify-content: center;
  background-color: white;
  .beta-label {
    color: var(--green);
    font-weight: bold;
    margin: 3px;
    cursor: default;
    user-select: none;
  }
}

.ui-drawer-left,
.ui-drawer-right {
  width: var(--sidebar-width);
  height: 100%;
  background: var(--sidebar-background);

  position: absolute;
}

.ui-drawer-left {
  left: 0;
  transform: translateX(-100%);
  z-index: 1291;
}
.close-left-sidebar {
  z-index: 1290;
}

.left-sidebar-open .ui-drawer-left {
  transform: translateX(0);
}

.ui-drawer-right {
  right: 0;
  transform: translateX(100%);
  z-index: 1293;
}
.close-right-sidebar {
  z-index: 1292;
}

.right-sidebar-open .ui-drawer-right {
  transform: translateX(0);
}

.close-left-sidebar,
.close-right-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: var(--sidebar-background);
}

.left-sidebar-open .close-left-sidebar,
.right-sidebar-open .close-right-sidebar {
  pointer-events: initial;
  opacity: 0.7;
}

.ui-app-row .ui-nav,
.ui-app-row .MapControl-top-left,
.ui-app-row .MapControl-left,
.ui-app-row .MapControl-bottom-left,
.ui-app-row .mapboxgl-ctrl-bottom-left,
.ui-app-row .mapboxgl-ctrl-top-left,
.ui-app-row .MapControl-top-right,
.ui-app-row .MapControl-bottom-right,
.ui-app-row .MapControl-right,
.ui-app-row .mapboxgl-ctrl-bottom-right,
.ui-app-row .mapboxgl-ctrl-top-right {
  transition: margin 220ms cubic-bezier(0.215, 0.61, 0.355, 1) 0ms;
}

/* left sidebar can persist */
@media screen and (min-width: 801px) {
  .ui-app-row.left-sidebar-open .ui-nav,
  .ui-app-row.left-sidebar-open .MapControl-top-left,
  .ui-app-row.left-sidebar-open .MapControl-bottom-left,
  .ui-app-row.left-sidebar-open .mapboxgl-ctrl-bottom-left,
  .ui-app-row.left-sidebar-open .mapboxgl-ctrl-top-left {
    margin-left: var(--sidebar-width);
  }

  .close-left-sidebar {
    display: none;
  }
  .ui-drawer-left {
    transition: none;
    position: absolute;
    z-index: -9999;
  }
  .left-sidebar-open .ui-drawer-left {
    position: initial;
    z-index: initial;
  }
}

.ui-right-sidebar-btn {
  display: none;
}

/* right sidebar can persist */
@media screen and (min-width: 1101px) {
  .ui-app-row.right-sidebar-open .ui-nav,
  .ui-app-row.right-sidebar-open .MapControl-top-right,
  .ui-app-row.right-sidebar-open .MapControl-right,
  .ui-app-row.right-sidebar-open .MapControl-bottom-right,
  .ui-app-row.right-sidebar-open .mapboxgl-ctrl-bottom-right,
  .ui-app-row.right-sidebar-open .mapboxgl-ctrl-top-right {
    margin-right: var(--sidebar-width);
  }
  .ui-right-sidebar-btn {
    display: initial;
  }
  .close-right-sidebar {
    display: none;
  }
  .ui-drawer-right {
    transition: none;
    position: absolute;
    z-index: -9999;
  }
  .right-sidebar-open .ui-drawer-right {
    position: initial;
    z-index: initial;
  }
}

@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  .ui-app,
  .ui-app-row,
  .ui-content,
  .ui-content-row {
    display: initial;
  }

  .ui-app,
  .ui-app-row {
    height: initial;
    overflow: visible;
  }

  .ui-nav,
  .ui-sidebar,
  .close-left-sidebar,
  .close-right-sidebar,
  .no-print {
    display: none !important;
  }
}
