.UploadFlightFilesProgressDialog {
  height: 450px;
}
.UploadFlightFilesProgressDialog_group {
  padding-left: 5px;
  padding-right: 5px;
}
.UploadFlightFilesProgressDialog_group-title {
  user-select: none;
  cursor: pointer;
}
.UploadFlightFilesProgressDialog_group-file-container {
  min-height: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
