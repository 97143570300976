#ReportTOC {
	position: relative;
}

#ReportTOC .report_primary {
	font-size: 1em;
	margin-bottom: 1em;
	text-transform: uppercase;
	color: var(--green);
	font-weight: 800;
}

#ReportTOC .ReportTOC__product_title {
	line-height: 1.8em;
	margin-top: 0.7em;
	margin-bottom: -0.25em;
	font-size: 0.9em;
}

#ReportTOC .ReportTOC__product_title {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 800;
	font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

#ReportTOC .ReportTOC__product_desc {
	opacity: 0.8;
	font-size: 0.86em;
}
