.container {
    padding: 2px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background-color: #FAFAFA;
    overflow: hidden;
    border-radius: 4px;
    .bar {
      height: 5px;
      background-color: var(--logo);
      transition: width 0.5s ease-in-out;
    }
    .bar:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .bar:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
