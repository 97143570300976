.kato-job .output {
  box-sizing: border-box;
  min-height: 19px;
  overflow-x: auto;
  width: 100%;
  font-size: 12pt;
  background-color: rgb(38, 38, 38);
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(127, 127, 127);
  padding: 12px;
  color: rgb(250, 250, 250);
  font-family: Monospace !important;
}
