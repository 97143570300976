.stage-stepper {
    & .MuiStep-root {
        & .MuiStepConnector-root {
            top: 10px;
            left: calc(-50% + 10px);
            right: calc(50% + 10px);

            & .MuiStepConnector-line {
                transition: border-color 300ms ease-in;
            }

            &.Mui-active {
                & .MuiStepConnector-line {
                    border-color: var(--green);
                }
            }
            &.Mui-completed {
                & .MuiStepConnector-line {
                    border-color: var(--green);
                }
            }
        }
    }
}

.stage-step {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid;
    border-color: #757575;
    transition: background-color 300ms ease-in, border-color 300ms ease-in;

    &.active {
        border-color: var(--green);
        background-color: var(--green);
    }

    &.completed {
        border-color: var(--green);
        background-color: var(rgba(255, 255, 255, 0));
    }
}

.stage-step-active-content {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--light-grey);
}

.stage-step-complete-content {
    padding: 2px;
    color: var(--green);
}


