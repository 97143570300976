.sample-plan-new-stage-form-container {
    position: relative;
    transition: min-width 200ms ease-in, 
        min-height 200ms ease-in, 
        max-width 200ms ease-in,
        max-height 200ms ease-in,
        width 200ms ease-in,
        height 200ms ease-in;
}

.sample-plan-new-stage-form {
    position: absolute;
    width: 100%;
    height: 100%;
}

.sample-plan-location-icon {
    height: 19px;
    -webkit-transition: color 200ms linear;
    -moz-transition: color 200ms linear;
    -o-transition: color 200ms linear;
    transition: color 200ms linear;
}

.sample-plan-block-location-icon.selected {
    color: #4ab663;
}