.sample-plan-dashboard {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 30px 10%;
}

.sample-plan-dashboard.mobile {
  padding: 0px;
}

.sample-plan-paper {
  padding: 25px;
}

.sample-plan-header {
  overflow: auto;
  padding: 25px;
  transition: background-color 300ms ease-in-out;
}

.sample-plan-filter-paper {
  overflow: auto;
  padding: 5px;
  width: fit-content;
  transition: background-color 300ms ease-in-out;
}

.sample-plan-options {
  // position: sticky;
  z-index: 10;
  top: 0;
  transition: margin 300ms ease-in-out;
}

.sample-plan-options.scrolling {
  margin: 0px 30px;
}

.sample-plan-paper.scrolling {
  background-color: var(--background2);
}

.sample-plan-filter-paper.scrolling {
  background-color: var(--background2);
}

@media only screen and (max-width: 600px) {
  .sample-plan-dashboard {
    padding: 0px;
  }
  .sample-plan-paper {
    padding: 4px;
  }
  .sample-plan-header {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}
