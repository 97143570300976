.FileDropTarget__container {
  position: relative;
  padding: 1em 1.6em;
  margin-bottom: 1em;
  margin-top: 2.5em;
}

.FileDropTarget__progress {
  z-index: 1000;
}

.FileDropTarget__fileList {
  max-height: 500px;
  overflow-y: auto;
}

.FileDropTarget__dropArea {
  border: dashed rgba(200, 200, 200, 0.4) 4px;
  background-color: rgba(200, 200, 200, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.FileDropTarget__dragText {
  text-align: center;
  color: grey;
  font-size: 36px;
}
