#ReportSummary {
  height: calc(100% - 1em);
  position: relative;
}

#ReportSummary .summary-container {
  padding: 36px;
  height: 100%;
}

#ReportSummary .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

#ReportSummary .info-title {
  width: 312px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

#ReportSummary .block-info-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: #0000008A;
}

#ReportSummary .sample-info {
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
}

#ReportSummary .sample-info-index {
  font-size: 10px;
  font-weight: 800;
  line-height: 24px;
}

#ReportSummary .info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#ReportSummary .name-line {
  width: 92%;
  margin: 30px 0px 0px 0px;
}

#ReportSummary .observation-line {
  height: 100%;
  width: 1px;
  margin-left: 24px;
  margin-right: 24px;
  border-color: #8C8A88;
  background: repeating-linear-gradient(to bottom,transparent 0 3px, #8C8A88 4px 8px) 80%/2px 100% no-repeat;
}
