.complete-kato-job-dialog {
	width: 500px;

	.job-notes {
		width: 100%;
		color: #fff;
		padding: 12px;
		font-size: 12pt;
		overflow-x: scroll;
		border-style: solid;
		border-width: 1px;
		border-color: rgb(127, 127, 127);
		margin-bottom: 8px;
		border-radius: 4px;
	}

	.fields {
		width: 100%;
		margin-bottom: 8px;
		.label {
			margin: 4;
		}
		> * {
			margin-top: 8px;
			width: 100%;
		}
	}
}
.dark {
	--notes-background: rgb(38, 38, 38);
}

.light {
	--notes-background: rgb(226, 226, 226);
}
