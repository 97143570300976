.MapLoader__container {
  background: white;
  position: absolute;
  height: 100%;
  width: 100%;
}

.MapLoader__icon_background {
  width: 150px;
  height: 150px;
  background: var(--logo);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10%;
  animation: fade 0.6s alternate infinite;
}

@keyframes fade {
  from {
    opacity: 0.2;
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1.05);
  }
}
