/*
  iOS:
  Double-tap zoom is disabled in index.html by putting an empty `onClick`
  handler on the root element. This has a side-effect of showing the tap
  highlight on mobile.
  So disable that.
*/
* {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
}
