.StatsWindow {
  position: relative;
  z-index: 9000;
}

.StatsWindowTab {
  padding: 8px;
  font-size: 12px;
  min-height: 0px;
}

.StatsWindow-Bar {
  padding: 6px 10px 0px 10px;
}

.StatsWindow-Title {
  padding-left: 4px;
}

.StatsWindow-Close {
  padding: 4px;
  height: 30px;
}

.StatsWindow-Dock {
  padding: 4px;
  height: 30px;
}

.StatsWindow-Download {
  padding: 4px;
  height: 30px;
  z-index: 9001; // Button appears to hide under tabs with z-index.
}

.StatsWindow-Current,
.StatsWindow-Historic {
  height: 195px;
  border-bottom: 1px solid var(--divider);
}

.StatsWindow.historic .StatsWindow-Historic {
  display: block;
}

.StatsWindow.historic .StatsWindow-Current {
  display: none;
}

.StatsWindow.current .StatsWindow-Current {
  display: block;
}

.StatsWindow.current .StatsWindow-Historic {
  display: none;
}

.StatsWindow-Current .hidden {
  display: none;
  flex-basis: 0%;
}

.pin-icon {
  color: var(--color);
  font-size: 22px;

  &.icon-crossed {
    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 90%;
      top: 2px;
      display: block;
      background-color: var(--color);
      left: 50%;
      transform: translate(-50%, 0) rotate(-45deg);
    }
  }
}

@media screen and (max-width: 600px) {
  .StatsWindow .StatsWindow-Historic {
    height: min-content;
  }
  .StatsWindow .StatsWindow-Current {
    height: min-content;
  }
}
