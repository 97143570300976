.requeue-job-dialog {
	width: 500px;
}
.requeue-job-dialog .cancelButton {
	color: rgba(0, 0, 0, 0.87);
	background-color: #42a5f5;
}
.requeue-job-dialog .cancelButton:hover {
	background-color: #1e88e5;
}
.requeue-job-dialog .errorButton {
	color: #fff;
	background-color: #f44336;
}
.requeue-job-dialog .errorButton:hover {
	background-color: #d32f2f;
}
.requeue-job-dialog .job-input {
	height: 200px;
	width: 100%;
	color: #fff;
	padding: 12px;
	font-size: 12pt;
	box-sizing: border-box;
	min-height: 19px;
	overflow-x: scroll;
	background: rgb(38, 38, 38);
	font-family: Monospace !important;
	border-style: solid;
	border-width: 1px;
	border-color: rgb(127, 127, 127);
	margin-bottom: 8px;
	border-radius: 4px;
}

.requeue-job-dialog .job-input > div {
	height: 100%;
}

.requeue-job-dialog .error {
	color: #f44336;
	margin-bottom: 8px;
}
.requeue-job-dialog .alert {
	border-color: rgba(255, 255, 255, 0.12);
	background-color: #424242;
}
.requeue-job-dialog .alert .swal-text {
	color: #fff;
}
