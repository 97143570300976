.group {
  min-width: 575px;
}
.parcel {
  margin: 12px;
}
.parcel .divider {
  margin-bottom: 0 !important;
}

.parcel .parcel-map {
  height: 300px;
  width: 300px;
  border-radius: 4px;
  border: 1px solid var(--border);
  box-shadow: var(--box-shadow);
  margin-right: 12px;
}

.filter {
  margin-left: 12px;
  margin-right: 12px;
}
