.data_Legend {
  width: 100%;
  display: flex;
  position: relative;
  font-size: 14px;
}

.data_Legend .item {
  display: inline-block;
  position: relative;
}

/* Popup__ref is normally display: inline-block */
.data_Legend .item .Popup__ref {
  display: block;
}

.data_Legend .itemValue {
  position: relative;
  z-index: 2;
}

.data_Legend .itemValueText {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
}

.data_Legend.selected .item.selectable:not(.selected) .itemColor {
  opacity: 0.35;
}

.data_Legend .item.selectable:hover .itemColor,
.data_Legend .item.selectable.selected .itemColor {
  z-index: 1;
  box-shadow: 0px 0px 2px 1px var(--color) inset, 0px 0px 2px 1px var(--color);
}

.data_Legend.horizontal {
  height: 60px;
}

.data_Legend.horizontal .item {
  height: 100%;
}

.data_Legend.horizontal .itemText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 40px;
}

.data_Legend.horizontal .itemLabel {
  width: 100%;
  text-align: center;
}

.data_Legend.horizontal .minLabel,
.data_Legend.horizontal .maxLabel {
  position: absolute;
  top: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 8px;
}
.data_Legend.horizontal .minLabel {
  text-align: left;
}
.data_Legend.horizontal .maxLabel {
  text-align: right;
}

.data_Legend.horizontal .itemColor {
  width: 100%;
  height: 20px;
}

.data_Legend.horizontal .itemColor.tall {
  width: 100%;
  height: 60%;
}

.data_Legend.horizontal .itemValue {
  width: 100%;
  height: 0.8em;
  text-align: right;
  transform-origin: bottom right;
  transform: rotate(-80deg);
  margin-top: -0.6em;
  margin-left: calc(0.3em - 100%);
}

.data_Legend.horizontal .itemValueRight {
  margin-left: 0.3em;
}

.data_Legend.vertical {
  flex-direction: column;
}

.data_Legend.vertical .item {
  display: inline-flex;
  flex-direction: row;
  padding: 8px;
}

.data_Legend.vertical .itemText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 20px;
}

.data_Legend.vertical .itemLabel {
  text-align: left;
  padding: 0 8px;
}
.data_Legend.vertical .itemColor {
  height: 20px;
  width: 40px;
  padding: 0 8px;
}

.data_Legend.horizontal .itemContainer {
  height: 60px;
  position: relative;
}
