.FocusedPointPopup h1 {
  margin-bottom: 4px;
  font-weight: 400;
}

.FocusedPointPopup span {
  opacity: 0.7;
}

.FocusedPointPopup span.btn {
  opacity: 1;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px 24px;
}

.FocusedPointPopup span.btn:hover {
  background: var(--hover);
}
