.ProcGroupsTable {
	.table {
		margin-top: 15px;
		table-layout: fixed;
		tr.group {
			background-color: var(--background-light);
			user-select: none;
			cursor: pointer;

			&:hover {
				background-color: var(--hover);
			}
		}
	}
}
