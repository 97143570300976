.EnhancedTableToolbar {
  position: relative !important;
  position: sticky !important;
  top: 0;
  background: var(--background);
  height: 64px;
  z-index: 20;  
}

.EnhancedTableToolbar .flex {
  flex-grow: 1;
}
