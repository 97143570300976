table.downloads {
  width: 100%;
  table-layout: fixed;
}

table.downloads .select {
  width: 5% !important;
}

table.downloads .download-name {
  width: 20% !important;
}

table.downloads .date-requested {
  width: 17.5% !important;
}

table.downloads .expiration-date {
  width: 17.5% !important;
}

table.downloads .status {
  width: 20% !important;
  text-transform: capitalize;
}

table.downloads .status.complete{
  color: #5CC46C;
}

table.downloads .status.error {
  color: #FD6F48;
}

table.downloads .files-number {
  width: 15% !important;
}

table.downloads .group-name {
  width: 20% !important;
}

table.downloads .flight-date {
  width: 15% !important;
}

table.downloads .filename {
  width: 20% !important;
}

table.downloads .actions {
  width: 15% !important;
}

