@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Lato');
:root {
	--logo: hsl(145, 83%, 43%);
	--green: #4ab663;
	--green-hover: hsl(134, 43%, 45%);
	--grey: hsl(134, 7%, 74%);
	--light-grey: hsl(134, 18%, 95%);
	--blue: hsl(200, 43%, 50%);
	--report-text: rgb(22, 22, 22);
	--yellow: hsl(60, 50%, 50%);
	--orange: hsl(40, 60%, 60%);
	--red: hsl(5, 43%, 59%);
	--red-hover: hsl(5, 43%, 54%);
	--spacing: 8px;
	--appbar-height: 48px;
	--secondary-color: #c05e40;
	--border-radius: 10px;
	--box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.12);	

	font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.dark,
.main-nav {
	--background: rgb(66, 66, 66);
	--background-light: rgb(80, 80, 80);
	--background-a: rgba(66, 66, 66, 0.9);
	--background2: rgb(48, 48, 48);
	--background3: rgba(0, 0, 0, 0.2);
	--background-selected: rgb(100, 100, 100);
	--background-underlay: rgba(48, 48, 48, 0.9);
	--color: rgb(255, 255, 255);
	--color-light: #ffffffb3;
	--border: rgb(175, 175, 175);
	--color-disabled: rgba(255, 255, 255, 0.3);
	--link-color: rgb(200, 200, 200);
	--link-color-hover: rgb(255, 255, 255);
	--divider: rgba(255, 255, 255, 0.12);
	--hover: rgba(255, 255, 255, 0.1);

	--sidebar-background: rgb(66 66 66);

	--info-color: blue;
	--error-color: red;
	--success-color: green;
}

.light {
	--background: rgb(250, 250, 250);
	--background-light: rgb(242, 242, 242);
	--background-a: rgba(255, 255, 255, 0.9);
	--background2: rgb(245, 245, 245);
	--background-selected: rgb(210, 210, 210);
	--background-underlay: rgba(228, 228, 228, 0.9);
	--color: rgb(22, 22, 22);
	--color-light: rgba(22, 22, 22, 0.6);
	--border: rgb(80, 80, 80);
	--color-disabled: rgba(22, 22, 22, 0.3);
	--link-color: rgb(22, 22, 22);
	--link-color-hover: rgb(48, 48, 48);
	--divider: rgba(66, 66, 66, 0.12);
	--hover: rgba(0, 0, 0, 0.1);

	--sidebar-background: #ffffff;

	--info-color: #42a5f5;
	--error-color: #ff7c7c;
	--success-color: #17ad17;
}

*,
*:before,
*:after {
	font-family: 'Lato', sans-serif;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* *:before,
*:after {
	color: var(--color) !important;
}

a {
	color: var(--color);
}

a:visited {
	color: var(--color);
}

@media print {
	.no-print {
		display: none;
	}
}

.grabbable {
	cursor: pointer;
	cursor: -webkit-grab;
	cursor: grab;
}

.grabbable:active {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

.swal-overlay {
	z-index: 2147483647;
}
/* sweet alert theming */
.swal-modal {
	background-color: var(--background) !important;
	color: var(--color) !important;

	.swal-text {
		color: var(--color) !important;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
			'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	}

	.swal-title {
		color: var(--color) !important;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
			'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	}

	.swal-button {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
			'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
		font-weight: normal;
		text-transform: uppercase;
	}

	.swal-button--confirm {
		background-color: var(--green);

		&:hover {
			background-color: var(--green-hover) !important;
		}
	}

	.swal-button--confirm-delete {
		background-color: var(--red);

		&:hover {
			background-color: var(--red-hover) !important;
		}
	}

	.swal-button--danger {
		background-color: var(--red);

		&:hover {
			background-color: var(--red-hover) !important;
		}
	}
}

.swal-modal .swal-text {
	color: var(--color) !important;
	font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.swal-modal .swal-title {
	color: var(--color) !important;
	font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.swal-button {
	font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: normal;
	text-transform: uppercase;
}

.swal-content__input {
	border-radius: 5px;
}

/* grid classes to mimic bootstrap 12-item grid styles */
.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 3em 1em;
}

.grid-xs-1 {
	grid-column: span 1;
}
.grid-xs-2 {
	grid-column: span 2;
}
.grid-xs-3 {
	grid-column: span 3;
}
.grid-xs-4 {
	grid-column: span 4;
}
.grid-xs-5 {
	grid-column: span 5;
}
.grid-xs-6 {
	grid-column: span 6;
}
.grid-xs-7 {
	grid-column: span 7;
}
.grid-xs-8 {
	grid-column: span 8;
}
.grid-xs-9 {
	grid-column: span 9;
}
.grid-xs-10 {
	grid-column: span 10;
}
.grid-xs-11 {
	grid-column: span 11;
}
.grid-xs-12 {
	grid-column: span 12;
}

.grid > .align-center {
	justify-self: center;
}

.grid > .align-right {
	justify-self: end;
}

/* mostly map data management views */
.multi-text-fields {
	padding-left: 1em;
	border-left: 3px solid hsla(134, 43%, 50%, 0.3);
}

/* app bar is always green, so text should always be white */
.AppBar,
.AppBar * {
	color: white;
}

.sticky {
	position: sticky !important;
	top: var(--appbar-height);
	padding: 1rem 0;
	z-index: 10;
	background: var(--background-a);
}

.Paper {
	border-radius: var(--border-radius);
	background-color: var(--background);
	box-shadow: var(--box-shadow);
	padding: 16px;
}

.Paper + .Paper {
	margin-top: 16px;
}

button:disabled *,
input:disabled * {
	color: var(--color-disabled);
}

.notification > div {
	background-color: var(--background) !important;
}

/* style tab buttons */

div[class^='MuiTabs-'],
div[class*=' MuiTabs-'] button {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

::-webkit-scrollbar {
  width: 21px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 21px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color:var(--light-grey);
}