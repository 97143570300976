:root {
	--blue-dark: #068bf8;
	--blue: #42a5f5;
	--green-dark: hsl(134, 43%, 40%);
	--job-cancelled: #f5bc42;
	--job-cancelled-hover: #f5b325;
	--job-timeout: hsl(0, 0%, 62%);
	--job-timeout-hover: hsl(0, 0%, 58%);
	--job-error: hsl(1, 77%, 55%);
	--job-error-hover: hsl(1, 77%, 50%);
	--job-foreground: hsl(0, 0%, 98%);
}

@keyframes job-rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes job-pulse {
	0% {
		background-color: var(--blue-dark);
	}
	100% {
		background-color: var(--blue);
	}
}

.job {
	width: 100%;
	color: var(--job-foreground);
	transition: background-color 0.25s, color 0.25s;
}

.job.expanded {
	margin: 0;
}

.job.running {
	animation: job-pulse 1s ease-in-out infinite;
	animation-direction: alternate;
	background-color: var(--blue);
}

.job.cancelled {
	background-color: var(--job-cancelled);
}
.job.cancelled:hover {
	background-color: var(--job-cancelled-hover);
}

.job.timeout {
	background-color: var(--job-timeout);
}
.job.timeout:hover {
	background-color: var(--job-timeout-hover);
}

.job.error {
	background-color: var(--job-error);
}
.job.error:hover {
	background-color: var(--job-error-hover);
}

.job.complete {
	background-color: var(--green);
}
.job.complete:hover {
	background-color: var(--green-dark);
}

.job-info .job-info-column {
	padding: 15px;
	flex: 1;
	margin-top: 15px;
	border: 1px solid var(--divider);
	border-width: 1.5px 0 1.5px 1.5px;
}

.job-info .job-info-column:first-child {
	border-top-left-radius: var(--border-radius);
	border-bottom-left-radius: var(--border-radius);
}

.job-info .job-info-column:last-child {
	border-width: 1.5px 1.5px 1.5px 1.5px;
	border-top-right-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

.job .expander-content {
	background-color: var(--background-light);
	width: 100%;
	color: var(--color);
	display: flex;
	flex-direction: column;
}

.job .expander-summary * {
	color: var(--job-foreground);
}

.job .expander-summary .running-icon {
	animation: job-rotate 1s linear infinite;
	transform-origin: center center;
	transform-box: fill-box;
}

.job .attempt-logs .log-line .log-number {
	margin-right: 5px;
	color: rgb(250, 250, 250);
}

.job .attempt-logs .log-line .log-timestamp {
	margin-right: 5px;
	color: rgb(250, 250, 250);
}

.job .attempt-logs .log-line .log-message {
	color: rgb(207, 207, 207);
}
