.role-select {
	&::before {
		border: none !important;
	}
	&::after {
		border: none !important;
	}
	&.disabled {
		opacity: 0.5;
	}
}
