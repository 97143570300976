.filter-buttons *:first-of-type .button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.filter-buttons *:last-of-type .button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.filter-buttons :not(*:last-of-type):not(*:first-of-type) .button {
  border-radius: 0;
}
