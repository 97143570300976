.map_controls_Toolbar {
  display: flex;
  flex-direction: column;
}

@media screen and (min-height: 650px) {
  .map_controls_Toolbar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    display: flex;
    flex-direction: column;
  }
}
