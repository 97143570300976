.FlightInformation {
  font-size: 0.95em;
}

.FlightInformation .FlightInformation__title {
  color: var(--green) !important;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
}

.hasParcel .FlightInformation .FlightInformation__title {
  font-size: 0.8em;
  line-height: 1.2;
  font-weight: 600;
}

.FlightInformation .FlightInformation__title:after {
  content: '';
  position: relative;
  display: block;
  width: 2.4em;
  height: 0.2em;
  margin-top: 0.4em;
  background: var(--green);
}

.FlightInformation .FlightInformation__description {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.FlightInformation .FlightInformation__description p {
  font-size: 0.85em;
  font-weight: 200;
}

.FlightInformation .FlightInformation__description p:nth-child(odd) {
  margin-top: 0.3em;
  font-weight: 600;
}

.hasParcel
  .FlightInformation
  .FlightInformation__description
  p:nth-child(even) {
  font-size: 0.7em;
}

.FlightInformation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.FlightInformation ul li:nth-child(odd) {
  background: var(--light-grey);
  margin: 0 calc(-2 * var(--spacing));
  padding: 0 calc(2 * var(--spacing));
}
