.password-strength {
	font-weight: bold !important;
	&.weak {
		color: red;
	}
	&.moderate {
		color: orange;
	}
	&.ok {
		color: yellow;
	}
	&.good {
		color: yellowgreen;
	}
	&.strong {
		color: lawngreen;
	}
}
